import React from "react";
import simulifeVideo from "../assets/works/intro_video.webm";
import chatbotVideo from "../assets/works/chatbot_video.mp4";
import bitcoinExploerer from "../assets/works/bitcoin_explorer.webp";
import pandarrativ from "../assets/works/pandarrative.png";
import rockHall from "../assets/works/rockhall.jpg";
import webScrapper from "../assets/works/webscraper.webp";

const Blog = () => {
  return (
    <>
      {/* <!-- ====================================== Section Blogs ===================================== --> */}
      <section className="blog-section" id="blog">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            My Work.
          </h2>
          <div className="line"></div>
        </div>
        {/* 1. */}
        {/* <div className="blog-main zoom_in">
          <div className="blog-img-section">
            <video controls className="blog-video">
              <source src={chatbotVideo} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <h3 className="post-date">Sept 2024 - Now | web development</h3>
            <h4 className="steps">
              AI Chatbot for Psychological Measurement
            </h4>
            <h5 className="est">
              An interactive AI agent for assessing pyschological intervierw questions for employers. Built using React.js, Django, MongoDB, and OpenAI.
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-non-hover" href="#blog-popup">
                Under Development
              </a>
            </div>
          </div>
        </div> */}
        {/* 2. */}
        <div className="blog-main zoom_in">
          <div className="blog-img-section">
            <img src={bitcoinExploerer} alt="bitcoin explorer" className="blog-img"></img>
            {/* <p className="blog-date">
              15<span>APR</span>
            </p> */}
          </div>
          <div>
            <h3 className="post-date">Oct 2024 - Now | Full-stack Development</h3>
            <h4 className="steps">
             Rust Bitcoin Explorer
            </h4>
            <h5 className="est">
              Bitcoin Explorer with blockchain data and dynamic visualization board. Built with Rust, React, D3.js and MySQL.
            </h5>
            <div className="wrapper blog-btn links-container">
              <a className="btn-hover" href="https://bcexplorer.bennetx.com/">
                Visit Website
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>

              <a className="btn-hover" href="https://github.com/YHX6/Bitcoin-Expolorer">
                Visit Github
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        {/* 3. */}
        <div className="blog-main zoom_in">
          <div className="blog-img-section ">
          <img src={pandarrativ} alt="pandarrativ.com" className="blog-img"></img>
            {/* <p className="blog-date">
              15<span>APR</span>
            </p> */}
          </div>
          <div>
            <h3 className="post-date">April 2024 - Now | Web Application</h3>
            <h4 className="steps">
              pandarrativ.com
            </h4>
            <h5 className="est">
             Shaping Social, Emotional, and Behavioral Skills Skills Through Narrative AI. Building home page for Pandarrativ.com.
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="http://pandarrativ.com/">
                Visit
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* 4. */}
        <div className="blog-main zoom_in">
          <div className="blog-img-section blog-img-section2">
          <img src={rockHall} alt="digital screen of RockHall" className="blog-img"></img>
            {/* <p className="blog-date">
              15<span>APR</span>
            </p> */}
          </div>
          <div>
            <h3 className="post-date">May - Aug 2024 | Full-stack Web Development</h3>
            <h4 className="steps">
             Rock&Roll Hall of Fame Internship
            </h4>
            <h5 className="est">
              Migrating backend services and developing full stack web apps for digital screens for Musuem. Built with Vue.js, Python, Microsoft Azure, Strapi.
            </h5>
            <div className="v-gap"></div>
            {/* <div className="wrapper blog-btn">
              <a className="btn-hover" href="#blog-popup">
                Releasing Soon
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div> */}
          </div>
        </div>
        {/* 5. */}
        <div className="blog-main zoom_in">
          <div className="blog-img-section">
            <video controls className="blog-video">
              <source src={simulifeVideo} type="video/webm" />
              Your browser does not support the video tag.
            </video>
            {/* <p className="blog-date">
              15<span>APR</span>
            </p> */}
          </div>
          <div>
            <h3 className="post-date">Sept 2023 - April 2024 | Full-stack Development</h3>
            <h4 className="steps">
             Simulife++ | AI storytelling system
            </h4>
            <h5 className="est">
              Lead the development of storytelling system with AI agents. Built with Django, React.js, Redis, MongoDB, AWS.
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-non-hover" href="#blog-popup">
                Releasing Soon
                {/* <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg> */}
              </a>
            </div>
          </div>
        </div>
        {/* 6. */}

        <div className="blog-main mb-0 zoom_in">
          <div className="blog-img-section ">
          <img src={webScrapper} alt="web crawler" className="blog-img"></img>
            {/* <p className="blog-date">
              10<span>APR</span>
            </p> */}
          </div>
          <div>
            <h3 className="post-date">Dec 2020 - March 2021 | Data Engineer & Software Development</h3>
            <h4 className="steps">
              Data Collection System and Preprocessing Pipline.
            </h4>
            <h5 className="est">
             Innovated data collection system and data preprocessing pipline of Lanju. Improvde data collection efficiency by 6 times and prcossed over 5 millions records of data.
            </h5>
            {/* <div className="wrapper blog-btn">
              <a className="btn-hover" href="http://pandarrativ.com/">
                Visit Website
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div> */}
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Blogs End ===================================== --> */}
    </>
  );
};
export default Blog;
